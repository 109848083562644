.table-holder-responsive {
  @media (max-width: 1200px) {
    table,
    tbody {
      display: flex;
      flex-direction: column;
    }
    thead {
      display: none;
    }
    tr {
      display: flex;
      flex-direction: column;
    }
    td,
    th {
      padding: 5px 0;
      border: 0;
      text-align: left;
    }
    td:last-of-type {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    tr:last-of-type td:last-of-type {
      border: 0;
      margin-bottom: 0;
    }
    tr td:only-child {
      border: 0;
    }
  }
}
